@media screen and (max-width: 768px) {
  header {
    .header {
      &__burger {
        display: block;

        &.active {
          span {
            &:nth-child(1) {
              transform: rotate(33deg);
              transform-origin: left;
            }
            &:nth-child(2) {
              width: 0;
            }
            &:nth-child(3) {
              transform: rotate(-33deg);
              transform-origin: left;
            }
          }
        }
      }
      &__nav {
        position: absolute;
        left: 40px;
        top: -15px;
        width: 100px;
        padding: 10px;
        background-color: #fff;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        transition: all .3s ease;
        opacity: 0;
        visibility: hidden;

        &.show {
          top: 15px;
          opacity: 1;
          visibility: visible;
        }
        ul {
          flex-direction: column;

          li {
            margin: 0 0 8px 0;
            text-align: center;
          }
        }
      }
    }
  }

  main {
    flex-direction: column-reverse;
  }
  
  aside {
    width: 100%;
    text-align: center;
    border-width: 1px 0 0 0;
    padding: 10px 0 0 0;
    margin: 0 0 20px 0;
  }
  
  .banner {
    width: calc(100% + 30px);
    margin: 0 -15px 20px;

    &__text {
      align-items: center;
    }
    &__bottom {
      text-align: center;
    }
  }

  .about {
    img {
      display: none;
    }
  }

  footer {
    margin: 0 -15px;
  }
}