@import "fonts";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  overflow-x: hidden;
}

body {
  font-size: 12px;
  font-weight: 400;
  font-family: Montserrat, Arial, sans-serif;
  color: #000;
  line-height: 1.2;
  letter-spacing: 0.1em;
  background-color: #fff;
}

a {
  color: inherit;
  line-height: 1;
  text-decoration: none;
  transition: all .3s ease;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

ul, ol {
  list-style-position: inside;
}

.wrap {
  width: 100%;
  max-width: 880px;
  padding: 0 15px;
  margin: 0 auto;
}

.btn {
  display: inline-block;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  text-decoration: underline;
  text-align: center;
  border-radius: 5px;
  background-color: #BFA486;
  padding: 8px 10px;
}

header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  margin-bottom: 15px;

  .header {
    &__burger {
      display: none;
      position: relative;
      width: 25px;
      height: 16px;

      span {
        position: absolute;
        width: 100%;
        height: 2px;
        top: 0;
        left: 0;
        background-color: #BFA486;
        transition: all .3s ease;

        &:nth-child(2) {
          top: 7px;
        }
        &:nth-child(3) {
          top: 14px;
        }
      }
    }
    &__logo {
      height: 40px;
      width: auto;

      img {
        height: 100%;
        width: auto;
      }
    }

    &__nav {
      ul {
        list-style: none;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        li {
          position: relative;
          margin-left: 50px;
          font-size: 10px;
          font-weight: 600;

          &:before {
            display: block;
            content: '';
            position: absolute;
            bottom: 4px;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: #000;
            opacity: 0;
            visibility: hidden;
            transition: all .3s ease;
          }

          &.current,
          &:hover {
            &:before {
              opacity: 1;
              visibility: visible;
              bottom: 0;
            }
          }
        }
      }
    }
  }
}

main {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;

  .home & {
    display: block;
  }
}

aside {
  width: 180px;
  padding: 0 10px 0 0;
  margin: 0 10px 20px 0;
  border-width: 0 1px 0 0;
  border-style: solid;
  border-color: #BFA486;

  .sidebar-block {
    margin-bottom: 24px;

    &__title {
      display: block;
      font-weight: 600;
      margin-bottom: 14px;
    }
    &__list {
      list-style: none;

      li {
        font-weight: 500;
        margin-bottom: 8px;
        
        &:hover {
          a {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

article {
  flex: 1;
}

.banner {
  height: 300px;
  width: 100%;
  background-image: url('../img/banner.jpg');
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  color: #fff;
  margin-bottom: 20px;

  &__text {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    padding: 15px;
  }
  &__title {
    font-size: 30px;
    font-weight: 500;
    font-style: italic;
    letter-spacing: 6px;

    strong {
      display: block;
      font-size: 50px;
      font-weight: 700;
      font-style: normal;
      line-height: 1;
      letter-spacing: 10px;
    }
  }
  &__bottom {
    text-align: right;
    font-size: 15px;
    font-weight: 500;
    font-style: italic;
    
    span {
      display: block;
      margin-bottom: 20px;
    }
  }
}

.content {
  margin-bottom: 15px;

  .home & {
    border-top: 1px solid #BFA486;
    padding-top: 10px;
  }
  & > * {
    margin-bottom: 20px;
  }
  &__title {
    display: block;
    color: #BFA486;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    text-decoration: underline;
    margin-bottom: 20px;
  }
  &:after {
    display: block;
    content: '';
    clear: both;
  }

  h1, h2, h3, h4, h5 {
    color: #BFA486;
    text-align: center;
    font-weight: 600;
  }
  h1 {
    font-size: 14px;
  }
  h2, h3 {
    font-size: 12px;
  }
  h4, h5 {
    font-size: 12px;
    font-weight: 500;
  }
  ul, ol {
    padding-left: 20px;
    line-height: 1.5;
  }
  p {
    line-height: 1.5;
  }
  img {
    &.full {
      width: 100%;
      height: auto;
    }
    &.left {
      float: left;
      margin-right: 15px;
    }
    &.right {
      float: right;
      margin-left: 15px;
    }
  }
}

.groups {
  p {
    strong {
      font-size: 15px;
      font-weight: 600;
      color: #BFA486;
    }
  }
}

footer {
  background-color: #2B2B2B;
  color: #fff;
  padding: 10px;

  .footer {
    &__copyright {
      text-align: center;
      font-size: 10px;
      font-weight: 600;
    }
  }
}

@import "media";