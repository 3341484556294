@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserrat.woff2") format("woff2"), url("../fonts/montserrat.woff") format("woff"), url("../fonts/montserrat.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserratmedium.woff2") format("woff2"), url("../fonts/montserratmedium.woff") format("woff"), url("../fonts/montserratmedium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserratsemibold.woff2") format("woff2"), url("../fonts/montserratsemibold.woff") format("woff"), url("../fonts/montserratsemibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html,
body {
  overflow-x: hidden;
}
body {
  font-size: 12px;
  font-weight: 400;
  font-family: Montserrat, Arial, sans-serif;
  color: #000;
  line-height: 1.2;
  letter-spacing: 0.1em;
  background-color: #fff;
}
a {
  color: inherit;
  line-height: 1;
  text-decoration: none;
  transition: all 0.3s ease;
}
a:hover {
  color: inherit;
  text-decoration: none;
}
ul,
ol {
  list-style-position: inside;
}
.wrap {
  width: 100%;
  max-width: 880px;
  padding: 0 15px;
  margin: 0 auto;
}
.btn {
  display: inline-block;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  text-decoration: underline;
  text-align: center;
  border-radius: 5px;
  background-color: #BFA486;
  padding: 8px 10px;
}
header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  margin-bottom: 15px;
}
header .header__burger {
  display: none;
  position: relative;
  width: 25px;
  height: 16px;
}
header .header__burger span {
  position: absolute;
  width: 100%;
  height: 2px;
  top: 0;
  left: 0;
  background-color: #BFA486;
  transition: all 0.3s ease;
}
header .header__burger span:nth-child(2) {
  top: 7px;
}
header .header__burger span:nth-child(3) {
  top: 14px;
}
header .header__logo {
  height: 40px;
  width: auto;
}
header .header__logo img {
  height: 100%;
  width: auto;
}
header .header__nav ul {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
header .header__nav ul li {
  position: relative;
  margin-left: 50px;
  font-size: 10px;
  font-weight: 600;
}
header .header__nav ul li:before {
  display: block;
  content: '';
  position: absolute;
  bottom: 4px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #000;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}
header .header__nav ul li.current:before,
header .header__nav ul li:hover:before {
  opacity: 1;
  visibility: visible;
  bottom: 0;
}
main {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
}
.home main {
  display: block;
}
aside {
  width: 180px;
  padding: 0 10px 0 0;
  margin: 0 10px 20px 0;
  border-width: 0 1px 0 0;
  border-style: solid;
  border-color: #BFA486;
}
aside .sidebar-block {
  margin-bottom: 24px;
}
aside .sidebar-block__title {
  display: block;
  font-weight: 600;
  margin-bottom: 14px;
}
aside .sidebar-block__list {
  list-style: none;
}
aside .sidebar-block__list li {
  font-weight: 500;
  margin-bottom: 8px;
}
aside .sidebar-block__list li:hover a {
  text-decoration: underline;
}
article {
  flex: 1;
}
.banner {
  height: 300px;
  width: 100%;
  background-image: url('../img/banner.jpg');
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  color: #fff;
  margin-bottom: 20px;
}
.banner__text {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  padding: 15px;
}
.banner__title {
  font-size: 30px;
  font-weight: 500;
  font-style: italic;
  letter-spacing: 6px;
}
.banner__title strong {
  display: block;
  font-size: 50px;
  font-weight: 700;
  font-style: normal;
  line-height: 1;
  letter-spacing: 10px;
}
.banner__bottom {
  text-align: right;
  font-size: 15px;
  font-weight: 500;
  font-style: italic;
}
.banner__bottom span {
  display: block;
  margin-bottom: 20px;
}
.content {
  margin-bottom: 15px;
}
.home .content {
  border-top: 1px solid #BFA486;
  padding-top: 10px;
}
.content > * {
  margin-bottom: 20px;
}
.content__title {
  display: block;
  color: #BFA486;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  text-decoration: underline;
  margin-bottom: 20px;
}
.content:after {
  display: block;
  content: '';
  clear: both;
}
.content h1,
.content h2,
.content h3,
.content h4,
.content h5 {
  color: #BFA486;
  text-align: center;
  font-weight: 600;
}
.content h1 {
  font-size: 14px;
}
.content h2,
.content h3 {
  font-size: 12px;
}
.content h4,
.content h5 {
  font-size: 12px;
  font-weight: 500;
}
.content ul,
.content ol {
  padding-left: 20px;
  line-height: 1.5;
}
.content p {
  line-height: 1.5;
}
.content img.full {
  width: 100%;
  height: auto;
}
.content img.left {
  float: left;
  margin-right: 15px;
}
.content img.right {
  float: right;
  margin-left: 15px;
}
.groups p strong {
  font-size: 15px;
  font-weight: 600;
  color: #BFA486;
}
footer {
  background-color: #2B2B2B;
  color: #fff;
  padding: 10px;
}
footer .footer__copyright {
  text-align: center;
  font-size: 10px;
  font-weight: 600;
}
@media screen and (max-width: 768px) {
  header .header__burger {
    display: block;
  }
  header .header__burger.active span:nth-child(1) {
    transform: rotate(33deg);
    transform-origin: left;
  }
  header .header__burger.active span:nth-child(2) {
    width: 0;
  }
  header .header__burger.active span:nth-child(3) {
    transform: rotate(-33deg);
    transform-origin: left;
  }
  header .header__nav {
    position: absolute;
    left: 40px;
    top: -15px;
    width: 100px;
    padding: 10px;
    background-color: #fff;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    transition: all 0.3s ease;
    opacity: 0;
    visibility: hidden;
  }
  header .header__nav.show {
    top: 15px;
    opacity: 1;
    visibility: visible;
  }
  header .header__nav ul {
    flex-direction: column;
  }
  header .header__nav ul li {
    margin: 0 0 8px 0;
    text-align: center;
  }
  main {
    flex-direction: column-reverse;
  }
  aside {
    width: 100%;
    text-align: center;
    border-width: 1px 0 0 0;
    padding: 10px 0 0 0;
    margin: 0 0 20px 0;
  }
  .banner {
    width: calc(100% + 30px);
    margin: 0 -15px 20px;
  }
  .banner__text {
    align-items: center;
  }
  .banner__bottom {
    text-align: center;
  }
  .about img {
    display: none;
  }
  footer {
    margin: 0 -15px;
  }
}
